

/* eslint-disable no-sparse-arrays */

import React, { useState, useEffect } from "react";
import axios from "axios";
import ReactQuill from "react-quill";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-quill/dist/quill.snow.css";

// Quill modules configuration
const modules = {
  toolbar: [
    [{ header: [1, 2, 3, 4, 5, 6] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [{ list: "ordered" }, { list: "bullet" }],
    ["link", "image"],
    [{ align: [] }],
    ["clean"],
  ],
};

const formats = [
  "header",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "link",
  "image",
  "align",
];

function AddNewBlog({ userEmail }) {
  const [title, setTitle] = useState("");
  const [summary, setSummary] = useState("");
  const [content, setContent] = useState("");
  const [image, setImage] = useState(null);
  const [category, setCategory] = useState("");
  const [newCategory, setNewCategory] = useState("");
  const [subcategory, setSubcategory] = useState("");
  const [newSubcategory, setNewSubcategory] = useState("");
  const [thumbnail, setThumbnail] = useState(null);
  const [tags, setTags] = useState("");
  const [categories, setCategories] = useState([]);
  const [subcategories, setSubcategories] = useState([]);
  const [authorName, setAuthorName] = useState("");
  const [authorEmail, setAuthorEmail] = useState("");
  const [userDetails, setUserDetails] = useState(null);
  const [useDetails, setUseDetails] = useState(true); // Toggle for using userDetails
  const [authorDesig, setAuthorDesig] = useState(""); // State for designation

console.log(userDetails)
  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const response = await axios.get(
          `https://blog-backend-ivfv.onrender.com/users/${userEmail}`
        );
        setUserDetails(response.data);
        if (response.data && useDetails) {
          setAuthorName(response.data.name);
          setAuthorEmail(response.data.email);
        }
      } catch (error) {
        console.error("Error fetching user details:", error);
      }
    };

    if (userEmail) {
      fetchUserDetails();
    }
  }, [userEmail, useDetails]);

  const fetchCategoriesAndSubcategories = async () => {
    try {
      const [categoriesResponse, subcategoriesResponse] = await Promise.all([
        axios.get("https://blog-backend-ivfv.onrender.com/categories"),
        axios.get("https://blog-backend-ivfv.onrender.com/subcategories"),
      ]);
      setCategories(categoriesResponse.data);
      setSubcategories(subcategoriesResponse.data);
    } catch (error) {
      console.error("Error fetching categories or subcategories:", error);
    }
  };

  const handleFileChange = (setter) => (e) => {
    if (e.target.files.length) {
      setter(e.target.files[0]);
    }
  };

  const createBlog = async () => {
    const formData = new FormData();
    formData.append("title", title);
    formData.append("summary", summary);
    formData.append("content", content);

    formData.append("category", newCategory || category);
    formData.append("newSubcategory", newSubcategory || subcategory);
    formData.append("tags", JSON.stringify(tags.split(",").map((tag) => tag.trim())));

    if (image) formData.append("image", image);
    if (thumbnail) formData.append("thumbnail", thumbnail);

    const author = {
      name: authorName,
      email: authorEmail,
      designation: authorDesig, // Include designation

      profilePic: userDetails?.profilePic || "",
    };
    formData.append("author", JSON.stringify(author));

    try {
      const response = await axios.post("https://blog-backend-ivfv.onrender.com/blogs", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      if (response.status === 201) {
        toast.success("Blog created successfully!");
        resetForm();
        fetchCategoriesAndSubcategories(); // Refresh categories and subcategories
      } else {
        throw new Error("Failed to create blog");
      }
    } catch (err) {
      console.error(err);
      toast.error("Error creating blog");
    }
  };

  const resetForm = () => {
    setTitle("");
    setSummary("");
    setContent("");
    setImage(null);
    setThumbnail(null);
    setCategory("");
    setSubcategory("");
    setNewCategory("");
    setNewSubcategory("");
    setTags("");
    setAuthorName("");
    setAuthorEmail("");
  };

  // Initial fetch
  useEffect(() => {
    fetchCategoriesAndSubcategories();
  }, []);

  return (
    <div className="container mt-5">
      <ToastContainer />
      <h1 className="mb-4 text-center">Create New Blog</h1>
      <form className="row g-4">
        {/* Thumbnail Upload */}
        <div className="col-md-12">
          <label className="form-label">Thumbnail</label>
          <input
            type="file"
            className="form-control"
            onChange={handleFileChange(setThumbnail)}
          />
        </div>
        {/* Title and Summary */}
        <div className="col-md-6">
          <label className="form-label">Title</label>
          <input
            type="text"
            className="form-control"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
        </div>
        <div className="col-md-6">
          <label className="form-label">Summary</label>
          <input
            type="text"
            className="form-control"
            value={summary}
            onChange={(e) => setSummary(e.target.value)}
          />
        </div>
        {/* Category and Subcategory */}
        <div className="col-md-6">
          <label className="form-label">Category</label>
          <select
            className="form-select"
            value={category}
            onChange={(e) => setCategory(e.target.value)}
          >
            <option value="">Select a category</option>
            {categories.map((cat) => (
              <option key={cat} value={cat}>
                {cat}
              </option>
            ))}
          </select>
          <input
            type="text"
            className="form-control mt-2"
            placeholder="Or create new category"
            value={newCategory}
            onChange={(e) => setNewCategory(e.target.value)}
          />
        </div>
        <div className="col-md-6">
          <label className="form-label">Subcategory</label>
          <select
            className="form-select"
            value={subcategory}
            onChange={(e) => setSubcategory(e.target.value)}
          >
            <option value="">Select a subcategory</option>
            {subcategories.map((subcat) => (
              <option key={subcat} value={subcat}>
                {subcat}
              </option>
            ))}
          </select>
          <input
            type="text"
            className="form-control mt-2"
            placeholder="Or create new subcategory"
            value={newSubcategory}
            onChange={(e) => setNewSubcategory(e.target.value)}
          />
        </div>
        {/* Content */}
        <div className="col-md-12">
          <label className="form-label">Content</label>
          <ReactQuill
            value={content}
            onChange={setContent}
            modules={modules}
            formats={formats}
            theme="snow"
          />
        </div>
        {/* Tags */}
        <div className="col-md-12">
          <label className="form-label">Tags</label>
          <input
            type="text"
            className="form-control"
            placeholder="Comma-separated tags"
            value={tags}
            onChange={(e) => setTags(e.target.value)}
          />
        </div>
        {/* Author Details */}
        <div className="col-md-12">
          <h2>Author Details</h2>
          <div className="form-check form-switch">
            <input
              className="form-check-input"
              type="checkbox"
              id="useDetails"
              checked={useDetails}
              onChange={() => setUseDetails(!useDetails)}
            />
            <label className="form-check-label" htmlFor="useDetails">
              Use User Details
            </label>
          </div>
          <input
            type="text"
            className="form-control mt-2"
            value={authorName}
            onChange={(e) => setAuthorName(e.target.value)}
            placeholder="Author Name"
            disabled={useDetails && userDetails}
          />
          <input
            type="email"
            className="form-control mt-2"
            value={authorEmail}
            onChange={(e) => setAuthorEmail(e.target.value)}
            placeholder="Author Email"
            disabled={useDetails && userDetails}
          />
          {/* <input
            type="text"
            className="form-control mt-2"
            value={authorDes}
            onChange={(e) => setAuthorDes(e.target.value)}
            placeholder="Author Description"
           
          /> */}
           <input
            type="text"
            className="form-control mt-2"
            value={useDetails && userDetails ? userDetails.designation : authorDesig}
            onChange={(e) => setAuthorDesig(e.target.value)}
            placeholder="Author Designation"
            disabled={useDetails && userDetails}
          />
        </div>
        {/* Submit Button */}
        <div className="col-12 text-center">
          <button
            type="button"
            className="btn btn-primary btn-lg"
            onClick={createBlog}
          >
            Submit
          </button>
        </div>
      </form>
    </div>
  );
}

export default AddNewBlog;
